import React, { FC } from 'react';
import { useStores } from '../../providers/store/use-stores';
import { GraphType } from '../../stores/domain';
import { observer } from 'mobx-react-lite';
import { MassSpectrumLineGraph } from './MassSpectrumLineGraph';
import { MassSpectrumBarGraph } from './MassSpectrumBarGraph';
import { StandardsBarGraph } from './StandardsBarGraph';

export const Graph: FC = observer(() => {
  const {
    graphStore: { type },
  } = useStores();

  switch (type) {
    case GraphType.MassSpectrumLine:
      return <MassSpectrumLineGraph />;
    case GraphType.MassSpectrumBar:
      return <MassSpectrumBarGraph />;
    case GraphType.StandardsBar:
    default:
      return <StandardsBarGraph />;
  }
});

import React, { FC } from 'react';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  BarChart,
  Label,
  ResponsiveContainer,
} from 'recharts';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../providers/store/use-stores';
import { yTickExponentialFormatter } from './utils';
import { CHART_SETTINGS } from './settings';
import COLORS from '../../colors.json';

export const StandardsBarGraph: FC = observer(() => {
  const {
    graphStore: { graphData },
  } = useStores();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={graphData} {...CHART_SETTINGS}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="sample" fontWeight={500}>
          <Label
            position="center"
            dy={30}
            fontWeight={600}
            fill={COLORS.black.DEFAULT}
          >
            Pb-208 Tests
          </Label>
        </XAxis>
        <YAxis dataKey="signal" tick={yTickExponentialFormatter}>
          <Label
            angle={-90}
            position="center"
            dx={-50}
            fontWeight={600}
            fill={COLORS.black.DEFAULT}
          >
            Pb-208 Signal (cps)
          </Label>
        </YAxis>
        <Tooltip
          content={({ active, payload }) => {
            if (active && payload && payload.length) {
              const { sample, signal } = payload[0].payload;

              return (
                <div className="bg-white p-2 rounded-md border-2 border-blue">
                  <p>
                    <span className="text-blue font-semibold mr-2">
                      Sample:
                    </span>
                    <span className="text-black">{sample}</span>
                  </p>
                  <p>
                    <span className="text-blue font-semibold mr-2">
                      Signal:
                    </span>
                    <span className="text-black">{signal}</span>
                  </p>
                </div>
              );
            }

            return null;
          }}
        />
        <Bar dataKey="signal" fill={COLORS.blue.DEFAULT} />
      </BarChart>
    </ResponsiveContainer>
  );
});

import React, { FC } from 'react';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  BarChart,
  Label,
  ResponsiveContainer,
} from 'recharts';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../providers/store/use-stores';
import { yTickExponentialFormatter } from './utils';
import { CHART_SETTINGS } from './settings';
import COLORS from '../../colors.json';

export const MassSpectrumBarGraph: FC = observer(() => {
  const {
    graphStore: { graphData },
  } = useStores();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={graphData} {...CHART_SETTINGS}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="isotope"
          fontWeight={500}
          ticks={[204, 205, 206, 207, 208]}
        >
          <Label
            position="center"
            dy={30}
            fontWeight={600}
            fill={COLORS.black.DEFAULT}
          >
            Mass (amu)
          </Label>
        </XAxis>
        <YAxis dataKey="signal" tick={yTickExponentialFormatter}>
          <Label
            angle={-90}
            position="center"
            dx={-50}
            fontWeight={600}
            fill={COLORS.black.DEFAULT}
          >
            Signal (cps)
          </Label>
        </YAxis>
        <Tooltip
          content={({ active, payload }) => {
            if (active && payload && payload.length) {
              const { isotope, signal } = payload[0].payload;

              if ([204, 206, 207, 208].includes(isotope)) {
                return (
                  <div className="bg-white p-2 rounded-md border-2 border-orange">
                    <p>
                      <span className="text-orange font-semibold mr-2">
                        Isotope:
                      </span>
                      <span className="text-black">{isotope}</span>
                    </p>
                    <p>
                      <span className="text-orange font-semibold mr-2">
                        Signal:
                      </span>
                      <span className="text-black">{signal}</span>
                    </p>
                  </div>
                );
              }
            }

            return null;
          }}
        />
        <Bar dataKey="signal" fill={COLORS.orange.DEFAULT} />
      </BarChart>
    </ResponsiveContainer>
  );
});

import React, { useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
  ICapiPanelAction,
  ModalRoot,
  CapiPanel,
  isSafari,
  NotificationArea,
} from 'asu-sim-toolkit';

import { useStores } from '../../providers/store/use-stores';
import { getCapi } from '../../capi';
import { Icon } from '../../components/Icon';
import { NotificationItem } from '../../components/NotificationItem/NotificationItem';
import { versionHistory } from '../../version-history';
import { isDebugMode } from '../../config';
import { LoadingScreen } from '../LoadingScreen/LoadingScreen';
import { Graph } from '../../components/Graph/Graph';

export const MainView = observer(() => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const {
    modalStore,
    notificationStore,
    recapStore: { showRecap, hasNewUpdates },
    fullscreenStore: {
      registerFullscreenElement,
      toggleFullscreen,
      fullscreenControlState,
      isFullscreen,
    },
    simContextStore: { forceSimMode, mode },
    appStore: { loadData, isReady },
    graphStore: { isGraphVisible },
  } = useStores();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!wrapperRef.current) return;

    showRecap();
    registerFullscreenElement(wrapperRef.current);
  }, [registerFullscreenElement, showRecap]);

  const debugActions: ICapiPanelAction[] = useMemo(
    () => {
      const actions: ICapiPanelAction[] = [];

      if (versionHistory.length > 0) {
        actions.push({
          iconId: hasNewUpdates ? 'newUpdates' : 'info',
          tooltip: 'Version history',
          onClick: () => showRecap(false),
        });
      }

      return actions;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasNewUpdates]
  );

  return (
    <div ref={wrapperRef} className="bg-white">
      <NotificationArea
        notificationStore={notificationStore}
        placement="top-center"
        itemComponent={NotificationItem}
      />
      <ModalRoot modalStore={modalStore}>
        <div className="h-screen w-screen overflow-hidden flex">
          {(isDebugMode || mode === 'AUTHOR') && (
            <CapiPanel
              additionalActions={debugActions}
              capi={getCapi()}
              onSimModeChange={forceSimMode}
            />
          )}
          {!isReady ? (
            <LoadingScreen />
          ) : (
            <div className="relative h-screen w-screen overflow-hidden flex items-center justify-center p-5">
              {isGraphVisible ? (
                <Graph />
              ) : (
                <span className="text-lg font-semibold">
                  This is invisible mode.
                </span>
              )}
            </div>
          )}
          {fullscreenControlState.isVisible && !isSafari && (
            <button
              className="absolute bg-white w-[40px] h-[40px] shadow-outer rounded-md right-md bottom-md z-50 border border-gray-100"
              disabled={!fullscreenControlState.isEnabled}
              onClick={toggleFullscreen}
            >
              <Icon id={isFullscreen ? 'fullscreen_exit' : 'fullscreen'} />
            </button>
          )}
        </div>
      </ModalRoot>
    </div>
  );
});

import { computed, makeObservable, observable } from 'mobx';
import { CapiBoundStore, ICAPI } from 'asu-sim-toolkit';
import { ICapiModel } from '../capi';
import { IGraphStore, IRootStore } from './types';
import { GraphBarCategories, GraphType, IIsotope } from './domain';
import { mapToBarCategory, mapToGraphType } from './mappers';

const MULTIPLIER = 10000000;

export class GraphStore
  extends CapiBoundStore<ICapiModel>
  implements IGraphStore
{
  private readonly rootStore: IRootStore;
  isGraphVisible: boolean;
  type: GraphType;
  barCategories: GraphBarCategories[];

  constructor(rootStore: IRootStore, capi: ICAPI<ICapiModel>) {
    super(capi);
    this.rootStore = rootStore;

    this.isGraphVisible = false;
    this.type = GraphType.MassSpectrumLine;
    this.barCategories = [
      GraphBarCategories.Above10,
      GraphBarCategories.Above5,
      GraphBarCategories.Sample,
    ];

    makeObservable(this, {
      isGraphVisible: observable,
      type: observable,
      barCategories: observable,
      graphData: computed,
    });

    this.synchronizeFromCapi('isGraphVisible', 'Sim.Graph.Displayed');
    this.synchronizeFromCapi('type', 'Sim.Graph.Type', mapToGraphType);
    this.synchronizeFromCapi(
      'barCategories',
      'Sim.Graph.BarCategories',
      (input) => input.map(mapToBarCategory)
    );
  }

  get graphData() {
    const chosenSampleValue = this.rootStore.sampleStore.value;

    if (!chosenSampleValue) return [];

    const isotopes = this.generateIsotopesData(chosenSampleValue);

    switch (this.type) {
      case GraphType.MassSpectrumLine:
        return this.generateGaussianData(isotopes);
      case GraphType.MassSpectrumBar:
        return isotopes;
      case GraphType.StandardsBar:
      default:
        return this.generateStandardsData(chosenSampleValue);
    }
  }

  generateStandardsData(sampleValue: number) {
    const county = this.rootStore.sampleStore;
    const data = [];

    if (this.barCategories.includes(GraphBarCategories.Above5)) {
      data.push({
        sample: '5 µg/dL Pb Standard',
        signal: Math.round(((5 * 52.4) / 100) * MULTIPLIER),
      });
    }

    if (this.barCategories.includes(GraphBarCategories.Above10)) {
      data.push({
        sample: '10 µg/dL Pb Standard',
        signal: Math.round(((10 * 52.4) / 100) * MULTIPLIER),
      });
    }

    if (this.barCategories.includes(GraphBarCategories.Sample)) {
      data.push({
        sample: `${county.countyName.replace(/County/gi, '')} #${
          county.no
        } Sample`,
        signal: Math.round(((sampleValue * 52.4) / 100) * MULTIPLIER),
      });
    }

    return data;
  }

  generateGaussianData(isotopes: IIsotope[]) {
    const data = [];
    const sigma = 0.02; // Standard deviation

    for (const isotope of isotopes) {
      const { isotope: x, signal } = isotope;

      for (let i = x - 0.1; i <= x + 0.1; i += 0.01) {
        const gaussian = signal * Math.exp(-((i - x) ** 2) / (2 * sigma ** 2));
        data.push({
          isotope: Math.round(i * 100) / 100,
          signal: gaussian,
        });
      }
    }

    return data;
  }

  generateIsotopesData(sampleValue: number) {
    return [
      {
        isotope: 204,
        signal: Math.round(((sampleValue * 1.4) / 100) * MULTIPLIER),
      },
      {
        isotope: 205,
        signal: 0,
      },
      {
        isotope: 206,
        signal: Math.round(((sampleValue * 2.41) / 100) * MULTIPLIER),
      },
      {
        isotope: 207,
        signal: Math.round(((sampleValue * 22.1) / 100) * MULTIPLIER),
      },
      {
        isotope: 208,
        signal: Math.round(((sampleValue * 52.4) / 100) * MULTIPLIER),
      },
    ];
  }
}

import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { CapiBoundStore, ICAPI } from 'asu-sim-toolkit';
import { XORShift } from 'random-seedable';
import { ICapiModel } from '../capi';
import { IRootStore, ISampleStore } from './types';
import { SampleYear } from './domain';
import { extractRoundedPercentageValue } from '../utils/extract-rounded-percentage-value';
import { generateValueInRange } from '../utils/generate-value-in-range';
import { RNG_SEED, MIN_PERCENT_ABOVE_10, MIN_PERCENT_ABOVE_5 } from './config';
import { shuffleArray } from '../utils/shuffle-array';
import { mapToFips } from './mappers';

export class SampleStore
  extends CapiBoundStore<ICapiModel>
  implements ISampleStore
{
  private readonly rootStore: IRootStore;
  no: number | null;
  value: number | null;
  year: SampleYear | null;
  fips: string;
  countyName: string;
  stateName: string;
  above5: number;
  above10: number;

  samples: number[];

  constructor(rootStore: IRootStore, capi: ICAPI<ICapiModel>) {
    super(capi);
    this.rootStore = rootStore;

    this.no = null;
    this.value = null;
    this.year = null;
    this.fips = '';
    this.countyName = '';
    this.stateName = '';
    this.above5 = 0;
    this.above10 = 0;

    this.samples = [];

    makeObservable(this, {
      no: observable,
      value: observable,
      year: observable,
      fips: observable,
      countyName: observable,
      stateName: observable,
      above5: observable,
      above10: observable,
      samples: observable,
      dependencyValues: computed,

      extractSamplesFromCounty: action.bound,
      getSampleByNo: action.bound,
    });

    this.synchronizeFromCapi('no', 'Sim.Sample.Number');
    this.synchronizeToCapi('value', 'Sim.Sample.Amount');
    this.bindToCapi('year', 'Sim.Sample.Year');
    this.synchronizeFromCapi('fips', 'Sim.Sample.Fips', mapToFips);
    this.bindToCapi('countyName', 'Sim.Sample.CountyName');
    this.bindToCapi('stateName', 'Sim.Sample.StateName');
    this.synchronizeToCapi('above5', 'Sim.Data.Above5');
    this.synchronizeToCapi('above10', 'Sim.Data.Above10');

    reaction(
      () => this.dependencyValues,
      (values) => {
        const { fips, no, year, isReady } = values;

        if (isReady && fips && no && year) {
          this.extractSamplesFromCounty();
          this.getSampleByNo();
        }
      }
    );
  }

  private generateSamples(percentAbove5: number, percentAbove10: number) {
    const random = new XORShift(
      Number(`${RNG_SEED}${this.fips}${this.year}`.replace(/[^0-9]/g, ''))
    );
    const sampleArray = [];
    const totalSamples = 100;

    for (let i = 0; i < percentAbove5; i++) {
      sampleArray.push(generateValueInRange(5, 10, () => random.float()));
    }

    for (let i = 0; i < percentAbove10; i++) {
      sampleArray.push(generateValueInRange(10, 20, () => random.float()));
    }

    for (let i = 0; i < totalSamples - percentAbove5 - percentAbove10; i++) {
      sampleArray.push(generateValueInRange(0.1, 5, () => random.float()));
    }

    return shuffleArray(sampleArray, () => random.float());
  }

  extractSamplesFromCounty() {
    const data = this.rootStore.appStore.data || [];

    if (data.length === 0 || !this.year || !this.fips) {
      throw new Error(
        'Something went wrong while extracting samples from county.'
      );
    }

    const matchingObject = data.find(
      (d) => d.year === this.year && d.fips === this.fips
    );

    if (!matchingObject) {
      this.countyName = '';
      this.stateName = '';
      this.above5 = 0;
      this.above10 = 0;
      return;
    }

    this.countyName = matchingObject.countyName;
    this.stateName = matchingObject.state;

    const percentAbove5 = extractRoundedPercentageValue(
      matchingObject.percentageOfChildrenWithBLLs5 || MIN_PERCENT_ABOVE_5
    );
    const percentAbove10 = extractRoundedPercentageValue(
      matchingObject.percentageOfChildrenWithBLLs10 || MIN_PERCENT_ABOVE_10
    );

    this.above5 = percentAbove5 + percentAbove10;
    this.above10 = percentAbove10;

    this.samples = this.generateSamples(percentAbove5, percentAbove10);
  }

  getSampleByNo() {
    if (!this.no || this.no > this.samples.length || this.no < 1) {
      throw new Error('Sample number exceeds allowable number.');
    }

    this.value = this.samples[this.no - 1];
  }

  get dependencyValues() {
    return {
      fips: this.fips,
      no: this.no,
      year: this.year,
      isReady: this.rootStore.appStore.isReady,
    };
  }
}

import { ICountyLeadData, countyLeadDataHeaders } from '../stores/domain';
import { parseCSV } from '../utils/parse-csv';
import { mapToCountyLeadData } from './mappers.csv';
import { ICountyLeadDataSource } from './types';
import { IApiCountyDataRow } from './types.csv';

export class CountyLeadDataSource implements ICountyLeadDataSource {
  constructor(private readonly source: string) {}

  async fetchData(): Promise<ICountyLeadData[]> {
    const res = await fetch(this.source);

    if (!res.ok) {
      throw new Error(`api error - ${res.status} (${res.statusText})`);
    }

    const csvText = await res.text();

    const parsedData = parseCSV(
      csvText,
      countyLeadDataHeaders
    ) as IApiCountyDataRow[];

    return parsedData.map(mapToCountyLeadData);
  }
}

export const generateValueInRange = (
  minValue: number,
  maxValue: number,
  randomizer?: () => number
) => {
  const precision = 10;
  const scaledMin = minValue * precision;
  const scaledMax = maxValue * precision;

  return (
    Math.floor(
      (randomizer ? randomizer() : Math.random()) * (scaledMax - scaledMin) +
        scaledMin
    ) / precision
  );
};

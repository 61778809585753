import {
  CAPI,
  CAPI_TYPES,
  FullscreenCapiSchema,
  GodModeCapiSchema,
  IFullscreenCapiModel,
  IGodModeCapiModel,
  TCapiSchema,
} from 'asu-sim-toolkit';

import { isDebugMode } from './config';

export enum CapiGraphBarCategories {
  Sample = 'Sample',
  Above5 = 'Above5',
  Above10 = 'Above10',
}

export enum CapiSampleYear {
  Y2012 = '2012',
  Y2016 = '2016',
}

export enum CapiGraphType {
  MassSpectrumLine = 'MassSpectrumLine',
  MassSpectrumBar = 'MassSpectrumBar',
  StandardsBar = 'StandardsBar',
}

export interface ICapiModel extends IFullscreenCapiModel, IGodModeCapiModel {
  'Sim.Sample.Number': number;
  'Sim.Sample.Amount': number;
  'Sim.Sample.Year': CapiSampleYear;
  'Sim.Sample.Fips': string;
  'Sim.Sample.CountyName': string;
  'Sim.Sample.StateName': string;

  'Sim.Data.Above5': number;
  'Sim.Data.Above10': number;

  'Sim.Graph.Displayed': boolean;
  'Sim.Graph.Type': CapiGraphType;
  'Sim.Graph.BarCategories': CapiGraphBarCategories[];
}

export const capiSchema: TCapiSchema<ICapiModel> = {
  ...GodModeCapiSchema,
  ...FullscreenCapiSchema,
  'Sim.Fullscreen.Visible': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Sample.Number': {
    initialValue: 0,
    options: {
      type: CAPI_TYPES.NUMBER,
    },
  },
  'Sim.Sample.Amount': {
    initialValue: 0,
    options: {
      type: CAPI_TYPES.NUMBER,
      readonly: true,
    },
  },
  'Sim.Sample.Year': {
    initialValue: CapiSampleYear.Y2012,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiSampleYear),
    },
  },
  'Sim.Sample.Fips': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Sample.CountyName': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Sample.StateName': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },

  'Sim.Data.Above5': {
    initialValue: 0,
    options: {
      type: CAPI_TYPES.NUMBER,
      readonly: true,
    },
  },
  'Sim.Data.Above10': {
    initialValue: 0,
    options: {
      type: CAPI_TYPES.NUMBER,
      readonly: true,
    },
  },

  'Sim.Graph.Displayed': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Graph.Type': {
    initialValue: CapiGraphType.StandardsBar,
    options: {
      type: CAPI_TYPES.ENUM,
      allowedValues: Object.values(CapiGraphType),
    },
  },
  'Sim.Graph.BarCategories': {
    initialValue: [
      CapiGraphBarCategories.Sample,
      CapiGraphBarCategories.Above5,
      CapiGraphBarCategories.Above10,
    ],
    options: {
      type: CAPI_TYPES.ARRAY,
      allowedValues: Object.values(CapiGraphBarCategories),
    },
  },
};

let capi: CAPI<ICapiModel>;

export function getCapi() {
  if (!capi) {
    capi = new CAPI<ICapiModel>(isDebugMode);
  }
  return capi;
}

import React from 'react';
import { YAxisProps } from 'recharts';

type CustomTickProps = YAxisProps & {
  payload: { value: number };
};

export const yTickExponentialFormatter = (props: CustomTickProps) => {
  const {
    x,
    y,
    payload: { value },
  } = props;
  const formattedValue = value.toExponential(1);

  const [coefficient, exponent] = formattedValue.split('e');

  const formattedCoefficient = parseFloat(coefficient).toFixed(1);

  const formattedExponentialValue = `${formattedCoefficient}E${exponent}`;

  return (
    <text
      x={x}
      y={y}
      dy={-4}
      textAnchor="end"
      fill="#666"
      fontWeight={500}
      style={{ textTransform: 'uppercase' }}
    >
      {formattedExponentialValue}
    </text>
  );
};

import { Mapper } from 'asu-sim-toolkit';
import { CapiGraphBarCategories, CapiGraphType } from '../capi';
import { GraphBarCategories, GraphType } from './domain';

const graphTypeDictionary: Record<CapiGraphType, GraphType> = {
  MassSpectrumBar: GraphType.MassSpectrumBar,
  MassSpectrumLine: GraphType.MassSpectrumLine,
  StandardsBar: GraphType.StandardsBar,
};

const graphBarCategoriesDictionary: Record<
  CapiGraphBarCategories,
  GraphBarCategories
> = {
  Sample: GraphBarCategories.Sample,
  Above5: GraphBarCategories.Above5,
  Above10: GraphBarCategories.Above10,
};

export const mapToGraphType: Mapper<CapiGraphType, GraphType> = (input) =>
  graphTypeDictionary[input];

export const mapToBarCategory: Mapper<
  CapiGraphBarCategories,
  GraphBarCategories
> = (input) => graphBarCategoriesDictionary[input];

export const mapToFips = (input: string) => input.replace(/^fip(s)?/i, '');

export const parseCSV = (csv: string, customHeaders: string[]) => {
  const rows = [];
  let rowValue = '';
  let isInsideQuotes = false;

  for (let i = 0; i < csv.length; i++) {
    const char = csv[i];
    if (char === '"') {
      isInsideQuotes = !isInsideQuotes;
    }
    if (char === '\n' && isInsideQuotes) continue;
    if (char === '\n') {
      rows.push(rowValue);
      rowValue = '';
      continue;
    }

    rowValue += char;
  }

  rows.push(rowValue);

  return rows.slice(1).map((row) => {
    let obj = {};
    let iterator = 0;
    let isInsideQuotes = false;
    let value = '';

    for (let i = 0; i < row.length; i++) {
      const char = row[i];

      if (char === ',' && isInsideQuotes) continue;
      if (char === '"') {
        isInsideQuotes = !isInsideQuotes;
        continue;
      }
      if (char === ',' && !isInsideQuotes) {
        obj = { ...obj, [customHeaders[iterator]]: value.trim() };
        iterator++;
        value = '';
        continue;
      }

      value += char;
    }

    obj = { ...obj, [customHeaders[iterator]]: value.trim() };

    return obj;
  });
};

import { action, makeObservable, observable } from 'mobx';
import { CapiBoundStore, ICAPI, NotificationType } from 'asu-sim-toolkit';
import { ICapiModel } from '../capi';
import { IAppStore, IRootStore } from './types';
import { ICountyLeadData } from './domain';
import { ICountyLeadDataSource } from '../data-sources/types';

export class AppStore extends CapiBoundStore<ICapiModel> implements IAppStore {
  private readonly rootStore: IRootStore;
  private readonly dataSource: ICountyLeadDataSource;
  data: ICountyLeadData[];
  isReady = false;

  constructor(
    rootStore: IRootStore,
    capi: ICAPI<ICapiModel>,
    countyLeadDataSource: ICountyLeadDataSource
  ) {
    super(capi);

    this.rootStore = rootStore;
    this.dataSource = countyLeadDataSource;
    this.data = [];

    makeObservable(this, {
      data: observable,
      isReady: observable,

      loadData: action.bound,
    });
  }

  async loadData(): Promise<void> {
    try {
      const data = await this.dataSource.fetchData();

      this.data = data;
      this.isReady = true;
    } catch (err) {
      this.rootStore.notificationStore.addNotification(
        'Something went wrong while loading the data',
        { type: NotificationType.error }
      );
    }
  }
}

export interface ICountyLeadData {
  year: string;
  fips: string;
  countyName: string;
  totalChildrenPopulation: number | null;
  numberOfChildrenTested: number | null;
  percentageOfChildrenTested: string | null;
  numberOfChildrenWithBLLs5: number | null;
  percentageOfChildrenWithBLLs5: string | null;
  numberOfChildrenWithBLLs10: number | null;
  percentageOfChildrenWithBLLs10: string | null;
  concentration_5_9: number | null;
  concentration_10_14: number | null;
  concentration_15_19: number | null;
  concentration_20_24: number | null;
  concentration_25_44: number | null;
  concentration_45: number | null;
  state: string;
  countyFips: string;
}

export const countyLeadDataHeaders = [
  'year',
  'fips',
  'countyName',
  'totalChildrenPopulation',
  'numberOfChildrenTested',
  'percentageOfChildrenTested',
  'numberOfChildrenWithBLLs5',
  'percentageOfChildrenWithBLLs5',
  'numberOfChildrenWithBLLs10',
  'percentageOfChildrenWithBLLs10',
  'concentration_5_9',
  'concentration_10_14',
  'concentration_15_19',
  'concentration_20_24',
  'concentration_25_44',
  'concentration_45',
  'state',
  'countyFips',
];

export enum GraphBarCategories {
  Sample = 'Sample',
  Above5 = 'Above5',
  Above10 = 'Above10',
}

export enum SampleYear {
  Y2012 = '2012',
  Y2016 = '2016',
}

export enum GraphType {
  MassSpectrumLine = 'MassSpectrumLine',
  MassSpectrumBar = 'MassSpectrumBar',
  StandardsBar = 'StandardsBar',
}

export interface IIsotope {
  isotope: number;
  signal: number;
}

import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';

export const CHART_SETTINGS: CategoricalChartProps = {
  width: 500,
  height: 300,
  margin: {
    top: 20,
    right: 20,
    left: 30,
    bottom: 30,
  },
};

import { Mapper } from 'asu-sim-toolkit';
import { IApiCountyDataRow } from './types.csv';
import { ICountyLeadData } from '../stores/domain';
import { FIPS_STATE } from '../stores/data';

function mapToType(input: string, type: 'number'): number | null;
function mapToType(input: string, type?: 'string'): string | null;
function mapToType(input: string, type?: 'string' | 'number') {
  if (/^N\/A$/i.test(input.trim())) {
    return null;
  }

  if (type === 'number') {
    return Number(input);
  }

  return input;
}

export const mapToCountyLeadData: Mapper<
  IApiCountyDataRow,
  ICountyLeadData
> = ({
  year,
  fips,
  countyName,
  totalChildrenPopulation,
  numberOfChildrenTested,
  percentageOfChildrenTested,
  numberOfChildrenWithBLLs5,
  percentageOfChildrenWithBLLs5,
  numberOfChildrenWithBLLs10,
  percentageOfChildrenWithBLLs10,
  concentration_5_9,
  concentration_10_14,
  concentration_15_19,
  concentration_20_24,
  concentration_25_44,
  concentration_45,
  state,
  countyFips,
}) => ({
  year,
  fips,
  countyName: countyName.replace(/County/gi, '').trim(),
  totalChildrenPopulation: mapToType(totalChildrenPopulation, 'number'),
  numberOfChildrenTested: mapToType(numberOfChildrenTested, 'number'),
  percentageOfChildrenTested: mapToType(percentageOfChildrenTested, 'string'),
  numberOfChildrenWithBLLs5: mapToType(numberOfChildrenWithBLLs5, 'number'),
  percentageOfChildrenWithBLLs5: mapToType(percentageOfChildrenWithBLLs5),
  numberOfChildrenWithBLLs10: mapToType(numberOfChildrenWithBLLs10, 'number'),
  percentageOfChildrenWithBLLs10: mapToType(
    percentageOfChildrenWithBLLs10,
    'string'
  ),
  concentration_5_9: mapToType(concentration_5_9, 'number'),
  concentration_10_14: mapToType(concentration_10_14, 'number'),
  concentration_15_19: mapToType(concentration_15_19, 'number'),
  concentration_20_24: mapToType(concentration_20_24, 'number'),
  concentration_25_44: mapToType(concentration_25_44, 'number'),
  concentration_45: mapToType(concentration_45, 'number'),
  state: FIPS_STATE[state],
  countyFips,
});
